import React, { useEffect, useRef } from 'react';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
    number: number;
    label: string;
    isExpanded: boolean;
    isCompleted: boolean;
    onEdit: () => void;
    Component: JSX.Element;
    Preview: JSX.Element;
    CTA?: JSX.Element;
    isFinalStep: boolean;
}

const Step: React.FC<Props> = ({ number, label, isExpanded, isCompleted, onEdit, Component, Preview, CTA, isFinalStep }) => {
    const { formatMessage } = useFormat({ name: 'common' });

    const ref = useRef<HTMLDivElement>(null);

    const headerClassName = useClassNames([
        'flex items-center justify-between border-b  p-15 transition md:p-30 lg:bg-white',
        {
            'border-primary-black bg-primary-black lg:border-none': isExpanded,
            'border-checkout-step-border bg-white': !isExpanded
        }
    ]);

    const numberClassName = useClassNames([
        'flex h-24 w-24 items-center justify-center rounded-full border font-heading text-14 font-bold leading-[38px] transition md:h-30 md:w-30 md:text-16 lg:border-primary-black',
        {
            'border-white bg-primary-black text-white': isExpanded,
            'border-primary-black bg-white text-primary-black': !isExpanded
        }
    ]);

    const labelClassName = useClassNames([
        'text-20 transition lg:text-primary-black',
        {
            'text-white': isExpanded,
            'text-primary-black': !isExpanded
        }
    ]);

    useEffect(() => {
        if (isExpanded && ref.current) ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [isExpanded]);

    return (
        <div className={`${!isExpanded ? 'border' : ''} border-checkout-step-border bg-white`} ref={ref}>
            <div className={headerClassName}>
                <div className="flex cursor-default items-center gap-12 lg:gap-16">
                    <span className={numberClassName}>{number}</span>
                    <h5 className={labelClassName}>{label}</h5>
                </div>
                {isCompleted && !isExpanded && (
                    <button
                        className="text-md text-secondary-black underline decoration-secondary-black underline-offset-2 hover:cursor-pointer"
                        onClick={onEdit}
                    >
                        {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                    </button>
                )}
                {isExpanded && (
                    <span className="text-white decoration-white lg:text-secondary-black lg:decoration-primary-black">{CTA}</span>
                )}
            </div>
            <div>
                {isFinalStep && <div className={isCompleted && !isExpanded ? 'block' : 'hidden'}>{Preview}</div>}
                <div className={isExpanded ? 'block' : 'hidden'}>{Component}</div>
            </div>
        </div>
    );
};

export default Step;
