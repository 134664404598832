import React from 'react';
import Image from 'frontastic/lib/image';
import type { FrontasticImage } from 'frontastic/lib/image/types';

export interface Props {
    logo: FrontasticImage;
}

const Header: React.FC<Props> = ({ logo }) => {
    return (
        <div className="flex items-center justify-between border-neutral-400 bg-white px-16 py-30 md:border-b md:px-40 md:py-42">
            <div className="relative h-28 w-144 md:h-56 md:w-278">
                <Image {...logo} style={{ objectFit: 'contain' }} priority />
            </div>
        </div>
    );
};

export default Header;
