import React, { useMemo } from 'react';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import Skeleton from 'react-loading-skeleton';
import { useResolveCCImageByType } from 'components/commercetools-ui/organisms/checkout/hooks/useResolveCCImage';
import { useCheckout } from 'components/commercetools-ui/organisms/checkout/provider';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import Preview from '../wrapper';

const PaymentPreview = () => {
    const { paymentData } = useCheckout();

    const resolveCCImage = useResolveCCImageByType();

    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const PreviewComponent = useMemo(() => {
        if (paymentData.type === PaymentMethodType.INVOICE) {
            return (
                <div className="flex items-center justify-between border border-checkout-step-border px-15 py-13 lg:border">
                    <span className="text-md">{formatCheckoutMessage({ id: paymentData.type, defaultMessage: paymentData.type })}</span>
                </div>
            );
        } else if (paymentData.type === PaymentMethodType.INSTALMENT) {
            return (
                <div className="flex items-center justify-between border border-checkout-step-border px-15 py-13 lg:justify-start lg:gap-24 lg:border">
                    <div>
                        <div className="flex items-center gap-16 leading-normal">
                            {formatCheckoutMessage({
                                id: 'instalment.options.text',
                                defaultMessage: 'Credit or debit card {count} installments',
                                values: { count: paymentData.paymentInstalmentOption }
                            })}
                        </div>
                    </div>
                    <div className=" max-w-70 lg:max-w-full">
                        <Image src={'/images/credit-debit-card.png'} className="h-20 w-full lg:h-24" alt="credit-debit-card" />
                    </div>
                </div>
            );
        } else if (paymentData.type === PaymentMethodType.CARD) {
            return (
                <div className="flex items-center justify-between border border-checkout-step-border px-15 py-13 lg:border">
                    <span className="text-md">
                        {formatCheckoutMessage({ id: 'creditdebitcard', defaultMessage: 'Credit or debit card' })}
                    </span>

                    <div className="flex items-center gap-6 md:gap-15">
                        {paymentData.paymentCardType && resolveCCImage(paymentData.paymentCardType) && (
                            <img alt="payment-cart" className="h-15" src={resolveCCImage(paymentData.paymentCardType)} />
                        )}
                        {paymentData?.expiryMonth && paymentData.expiryYear ? (
                            <p className="text-md">
                                ... {paymentData.expiryMonth}/{paymentData.expiryYear}
                            </p>
                        ) : (
                            <p className="text-md">
                                {formatCheckoutMessage({ id: 'payment.label.my.card', defaultMessage: 'My Saved Card' })}
                            </p>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex items-center justify-between border border-checkout-step-border px-15 py-13 lg:border">
                    <Skeleton containerClassName="contents" />
                </div>
            );
        }
    }, [paymentData, resolveCCImage]);

    return <Preview>{PreviewComponent}</Preview>;
};

export default PaymentPreview;
