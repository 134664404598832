import React, { useEffect, useState } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useCart } from 'frontastic';
import Preview from '../wrapper';

const AddressesPreview = () => {
    const { data } = useCart();
    const [loading, setLoading] = useState(true);
    const billingAddress = data?.billingAddress ?? {};

    useEffect(() => {
        if (data) {
            setLoading(false);
        }
    }, [data]);

    return (
        <Preview>
            <div className="border-checkout-step-border p-0 md:px-15 md:py-13 lg:border">
                <Typography className="my-5 text-md" asSkeleton={loading}>
                    {`${billingAddress.firstName} ${billingAddress.lastName ?? ''}`}
                </Typography>
                <Typography className="my-5 text-md" asSkeleton={loading}>
                    {billingAddress.streetName}
                </Typography>
                <Typography className="my-5 text-md" asSkeleton={loading}>
                    {`${billingAddress.postalCode} ${billingAddress.city}`}
                </Typography>
            </div>
        </Preview>
    );
};

export default AddressesPreview;
