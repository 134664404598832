'use client';

import React from 'react';
import type { CheckoutWrappedProps } from 'components/commercetools-ui/organisms/checkout';
import Checkout from 'components/commercetools-ui/organisms/checkout';
import Redirect from 'helpers/redirect';
import { useAccount, useCart } from 'frontastic/hooks';
import type { TasticProps } from '../types';

const CheckoutTastic = ({ data }: TasticProps<CheckoutWrappedProps>) => {
    const { loggedIn, accountLoading, accountBlocked } = useAccount();
    const { isLoading, isEmpty } = useCart();

    if (!loggedIn && !accountLoading) return <Redirect target="/" />;
    if (isEmpty && !isLoading) return <Redirect target="/" />;
    if (accountBlocked) return <Redirect target="/?blocked=true" />;
    //Fully customized checkout
    return <Checkout {...data} />;
};

export default CheckoutTastic;
