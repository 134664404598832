import { useCallback, useState } from 'react';
import { validate } from '@wilm/shared-types/validation-rules';
import { paymentFields } from '@wilm/shared-types/validation-rules/payment';

const usePoNumber = () => {
    const [poNumberField, setPoNumberField] = useState({
        ...paymentFields.poNumber,
        validation: { ...paymentFields.poNumber.validation, required: false }
    });

    const setPoNumber = useCallback((value: string) => {
        setPoNumberField(prev => ({ ...prev, value }));
    }, []);

    const validatePoNumber = useCallback(() => {
        const errors = validate(poNumberField, { poNumberField });
        return errors;
    }, [poNumberField]);

    return { poNumberField, setPoNumber, validatePoNumber };
};

export default usePoNumber;
