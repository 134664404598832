import React, { useCallback, useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import AddressModal from '../address-modal';
export interface Props {
    setSameAsBillingAddress: (value: boolean) => void;
}

const CreateAddress: React.FC<Props> = ({ setSameAsBillingAddress }) => {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = useCallback(() => setIsOpen(false), []);
    const { loggedIn, billingAddresses } = useAccount();
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    if (!loggedIn) return <></>;

    return billingAddresses.length > 0 ? (
        <>
            <button
                data-cy="add-new-address"
                className="text-14 underline underline-offset-2 hover:cursor-pointer"
                onClick={() => setIsOpen(true)}
            >
                {`${formatAccountMessage({ id: 'address.add', defaultMessage: 'Add new address' })} +`}
            </button>
            <AddressModal isOpen={isOpen} closeModal={closeModal} setSameAsBillingAddress={setSameAsBillingAddress} />
        </>
    ) : (
        <></>
    );
};

export default CreateAddress;
